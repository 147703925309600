import React from "react"
import {Link} from "gatsby"
import ThemeChanger from "../components/themeChanger"

export default (props) => (
  <nav className="navigation"> 
     <a href='https://www.linkedin.com/in/kunjanshah/'>LinkedIn</a>
     <a href='https://www.threads.net/@kunjanshah'>Threads</a>
     <a href='https://instagram.com/kunjanshah'>Instagram</a>
    <ThemeChanger/>
  </nav>
  
)
